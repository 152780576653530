import HODimg from '../images/Co-Ordinators/sumathi.png'
import AssociateProf from '../images/Co-Ordinators/praneesh.png'
import AssociateProf2 from '../images/Co-Ordinators/manoj.png'

//office bearers :

import chairman from '../images/Co-Ordinators/tinobretty.jpg';
import assistantChairman from '../images/Co-Ordinators/john samuel.jpg';
import secretary from '../images/Co-Ordinators/secretary.jpg'
import viceChairman from '../images/Co-Ordinators/kanishka.jpg'

// import sampleImg from '../images/logo-removebg-preview (1).png'








export const staffCoordinators = [
    { name: 'Dr.N.Sumathi', img: HODimg, dept :"B.Sc INFORMATION TECHNOLOGY" , intro: 'Head of the Department' },
    { name: 'Dr.M.Praneesh', img: AssociateProf,dept:"B.Sc COMPUTER SCIENCE WITH DATA ANALYTICS", intro: 'Associate Professor' },
    { name: 'Mr.S.Manoj', img: AssociateProf2,dept:"B.Sc COMPUTER SCIENCE WITH COGNITIVE SYSTEM", intro: 'Assistant Professor ' }
];

export const officeBearers = [
    { role: 'Chairman', name: 'Tino Britty J', img: chairman, dept: 'B.Sc CS',  instagram:'https://www.instagram.com/brittytino/' , linkedin:'https://www.linkedin.com/in/brittytino', portfolio:'https://tinobritty.tech/'},
    { role: 'Vice Chairman', name: 'John Samuel', img: assistantChairman, dept: 'B.Sc IT', instagram:'https://www.instagram.com/samjohn_offl' , linkedin:'https://www.linkedin.com/in/samjohnoffl', portfolio:'https://johnsamuel.in/' },
    { role: 'Secretary', name: 'Pravin B', img: secretary, dept:'B.Sc CS & DA', instagram : 'https://www.instagram.com/bpravin._/' , linkedin : 'https://www.linkedin.com/in/pravinboopathi/' , portfolio : 'https://pravin-boopathi.netlify.app/' },
    { role: 'Joint Secretary', name: 'Kanishka C', img: viceChairman, dept:'B.Sc CS (AI & DS)', instagram:'https://www.instagram.com/kaniskha_chandrasekar/' , linkedin: 'https://www.linkedin.com/in/kaniskha-c-2124a5292/'},
    
];
